
import React, { useEffect, useState } from 'react';
import { FaCalendar, FaCalendarCheck, FaCalendarDay, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import IframePlayer from '../../../../components/IframePlayer';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <>

        <Container className='capa'>

            <div className='column max-500'>
                <img src='/apoio/mostra.jpeg' className='max-500' style={{ width: '100%', maxWidth: '600px', marginBottom: '30px' }} />
                <h2 style={{ fontSize: '26px', color: '#fff', width: '100%', textAlign: 'center', marginBottom: '30px' }}>29 a 31 de outubro de 2024</h2>

            </div>
            <Capa  >
                <img src='/apoio/seminario.jpeg' className='max-500' style={{ width: '100%', maxWidth: '300px' }} />
                <Link className='full-width' to="/app/mostra-2024/inscricao">  <button className='greenButtonBig bold yellow full-width' style={{ maxWidth: '300px' }}>

                    <strong> INSCREVA-SE</strong>
                </button>
                </Link>
                <Link className='full-width' to="/app/mostra-2024/login">  <button className='greenButtonBig  bold purple  full-width' style={{ maxWidth: '300px' }}>

                    <strong>JÁ TENHO CADASTRO</strong>
                </button>
                </Link>
                <a className='full-width' href="/quero-ser-monitor">  <button className='greenButtonBig  bold purple  full-width' style={{ maxWidth: '300px' }}>

                    <strong>QUERO SER MONITOR</strong>
                </button>
                </a>
                <a className='full-width' href="quero-ser-avaliador">  <button className='greenButtonBig  bold purple  full-width' style={{ maxWidth: '300px' }}>

                    <strong>QUERO SER AVALIADOR</strong>
                </button>
                </a>
                <a className='full-width' href="/apoio/2024_programacao_v3.pdf" download>  <button className='greenButtonBig  bold purple  full-width' style={{ maxWidth: '300px' }}>

                    <strong>PROGRAMAÇÃO</strong>
                </button>
                </a>
                <a className='full-width' href="/apoio/2024_regulamento_X_Mostra_Ensino_Pesquisa_Extensao.pdf" download>  <button className='greenButtonBig  bold purple  full-width' style={{ maxWidth: '300px' }}>

                    <strong>REGULAMENTO</strong>
                </button>
                </a>




            </Capa>


        </Container >

    </>
}

export default HomeApplication;